@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0b.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_bZF3gnD_g.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE3gnD_g.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_c5H3gnD_g.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu4mxK.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmEU9fBBc4.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmWUlfBBc4.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmYUtfBBc4.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.css-3d4c1k-ScrollContainer{
  display: inline
}

.css-ittymf-ScrollContainer {
  height: 100%;
}
#ratings-content{
  margin-right: 20px;
}
@media only screen and (max-width: 485px) {
  #ratings-content {
      margin-right: 0px;
  }
}
.flex-container {
  display: flex;
  flex-Direction: row;
  justify-content: center;
  
}
@media only screen and (max-width: 643px) {
  .flex-container {
    display: block;
  }
}

/* 
  Leaderboard Widget
*/

:root {
  --action-gold-color: #f3bc2a;
  --neutral-trueGray-color: #c0c0c0;
}

#container {
  border: 1px solid var(--neutral-trueGray-color);
  max-width: 1310px;
}

.leaderboard-container {
  width: 100%;
  padding: 8px 0px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.leaderboard-container:hover {
  cursor: pointer;
} 

.leaderboard-text {
  font-family: 'Montserrat';
  font-size: 28px;
  line-height: 28px;
  font-weight: 800;
  text-align: left;
  margin: 0 0 0 16px;
}

#read-reviews-btn {
  margin-left: -50px;
}

#read-reviews-btn,
#read-reviews-btn span {
  height: 37px;
}

.rating-container {
  display: flex;
  align-items: center;
  min-width: 310px;
  padding: 8px 0;
}

.kbb-logo {
  padding-top: 6px;
}

.right-rating-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;
}

.rating-count-container {
  display: flex;
}

.rating-number {
  font-family: 'Montserrat';
  font-size: 46px;
  font-weight: 800;
  line-height: 48px;
  margin: 0;
}

.rating-stars-desktop {
  margin-top: 4px;
  margin-left: 11px;
}

.rating-stars-desktop img {
  height: 28px;
}

.rating-stars-mobile {
  display: none;
}

.rating-desc-text {
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  margin: 0;
}

.error-container {
  width: 100%;
  min-height: 111px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1280px) {
  #read-reviews-btn {
    margin: 0 14px 0 10px;
  }
}

@media only screen and (max-width: 860px) {
  .leaderboard-text {
    font-size: 22px;
    line-height: 24px;
  }

  #read-reviews-btn {
    margin-left: 10px;
  }

  #read-reviews-btn,
  #read-reviews-btn span {
    font-size: 14px;
  }
}

/* Tablet */
@media only screen and (max-width: 768px) {
  .leaderboard-text {
    font-size: 24px;
    line-height: 28px;
    text-align: left;
  }

  #read-reviews-btn,
  #read-reviews-btn span {
    width: 146px;
    font-size: 14px;
  }

  .rating-container {
    margin: -8px 16px 0 0;
    min-width: 140px;
  }

  .right-rating-container {
    max-width: 140px;
    margin-left: 6px;
  }

  .kbb-logo {
    width: 44px;
    height: 60px;
    padding-top: 10px;
  }

  .rating-number {
    font-size: 30px;
  }

  .rating-stars-mobile {
    display: block;
    margin-top: 6px;
    margin-left: 4px;
  }

  .rating-stars-mobile img {
    width: 25px;
    height: 23px;
  }

  .rating-stars-desktop {
    display: none;
  }

  .rating-desc-text {
    font-size: 10px;
    line-height: 12px;
    width: 100px;
  }

  .error-container {
    min-height: 100px;
  }
}

@media only screen and (max-width: 510px) {
  .leaderboard-container {
    flex-wrap: wrap;
  }

  .rating-container {
    margin-right: 10px;
    padding: 4px 0;
  }
}

/* Mobile */
@media only screen and (max-width: 360px) {
  .leaderboard-container {
    flex-wrap: wrap;
    padding: 10px 0;
  }

  .leaderboard-text {
    font-size: 18px;
    line-height: 24px;
    margin: 0 12px 0 12px;
  }

  #read-reviews-btn {
    margin: 0 0 0 12px;
  }

  #read-reviews-btn,
  #read-reviews-btn span {
    width: 127px;
    font-size: 14px;
  }

  .rating-container {
    width: 138px;
    min-width: 138px;
    padding: 6px 0;
    margin-right: 12px;
    
  }

  .right-rating-container {
    margin-left: 8px;
  }

  .error-container {
    min-height: 150px;
  }
}


/* Leaderboard Skeleton */
.leaderboard-skeleton-container {
  width: 100%;
  border: 1px solid var(--neutral-trueGray-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  max-width: 1310px;
}

.leaderboard-text-skeleton {
  margin-left: 16px;
  width: 40%;
}

.leaderboard-text-skeleton > div:first-child,
.read-reviews-btn-skeleton > div:first-child,
.right-section > div:first-child {
  margin-bottom: 15px;
}

.read-reviews-btn-skeleton {
  width: 15%;
}

.rating-skeleton {
  display: flex;
  align-items: center;
  width: 20%;
  padding: 15px 10px 15px 0;
}

.right-section {
  width: 70%;
  margin-left: 10px;
}

@media only screen and (max-width: 768px) {
  .leaderboard-text-skeleton {
    width: 45%;
  }

  .read-reviews-btn-skeleton {
    margin-left: 50px;
    width: 10%;
  }

  .rating-skeleton {
    padding: 9px 40px 9px 0;
  }
}

@media only screen and (max-width: 360px) {
  .leaderboard-skeleton-container {
    flex-wrap: wrap;
    justify-content: normal;
    padding: 12px 0;
  }

  .leaderboard-text-skeleton {
    width: 240px;
    margin-left: 16px;
  }

  .read-reviews-btn-skeleton {
    width: 100px;
    margin: 10px 0 0 16px;
  }

  .rating-skeleton {
    width: 100px;
    margin: 10px 0 0 40px;
  }
}